import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'

import { useWindowWidth } from 'core/ui/hooks/useWindowWidth'

import { InputGroup } from 'legacy/components/InputGroup/InputGroup'
import { LocationInput } from 'legacy/components/LocationInput/LocationInput'
import { Select } from 'legacy/components/Select/Select'
import { screen } from 'legacy/styles/screen'

import { laneType } from '../propTypes'

const RADIUS_AVAILABLE_VALUES = [10, 20, 50, 100, 150, 200, 300]

const RADIUS_OPTIONS = RADIUS_AVAILABLE_VALUES.map(someRadiusValue => ({
  label: someRadiusValue ? `${someRadiusValue} mi` : `None`,
  value: someRadiusValue,
}))

export const DEFAULT_RADIUS_VALUE = RADIUS_AVAILABLE_VALUES[4]

const SelectLaneContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  ${screen.md} {
    flex-wrap: nowrap;
  }
`

const SelectLaneStopsContainer = styled.div`
  position: relative;

  display: block;
  width: 100%;
  margin-bottom: 8px;

  ${screen.md} {
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    > :not(:first-child) {
      margin-left: 8px;
    }
  }
`

const RadiusSelectWrapper = styled.div`
  width: 200px;

  ${screen.md} {
    width: 120px;
    min-width: 100px;
    margin-left: 6px;
  }
`

function isLaneValid({ pickup, delivery }) {
  return Boolean(pickup || delivery)
}

export function SelectLane({ onChange, lane }) {
  const isMobile = useWindowWidth() < 768

  const setLaneStopLocation = useCallback(
    (stop, location) => {
      const nextLane = {
        ...lane,
        [stop]: location,
      }

      if (isLaneValid(nextLane)) {
        if (lane[stop]?.description !== nextLane[stop]?.description) onChange(nextLane)
      } else {
        onChange()
      }
    },
    [lane, onChange]
  )

  const setLaneRadius = useCallback(
    radius => {
      if (radius === lane.radius) return

      const nextLane = {
        ...lane,
        radius,
      }

      onChange(nextLane)
    },
    [lane, onChange]
  )

  const handleFieldChange = useCallback(
    (location, event) => setLaneStopLocation(event.target.name, location),
    [setLaneStopLocation]
  )

  const handleRadiusChange = useCallback(
    radius => {
      if (radius === null) {
        // happens when user opens select on mobile but close it without selecting an option
        return setLaneRadius(DEFAULT_RADIUS_VALUE)
      }

      const parsedRadius = parseInt(radius, 10)
      return setLaneRadius(parsedRadius)
    },
    [setLaneRadius]
  )

  const handleFieldClearButtonClick = useCallback(
    stopName => setLaneStopLocation(stopName, null),
    [setLaneStopLocation]
  )

  const originInput = useMemo(
    () => (
      <>
        <LocationInput
          name="pickup"
          placeholder="Pickup"
          onChange={handleFieldChange}
          value={lane.pickup?.description}
          useFloatingLabel
          forcedPlaceholder={lane.delivery?.description ? 'Anywhere' : ''}
          onClearButtonClick={handleFieldClearButtonClick}
          data-testid="pickup"
        />
      </>
    ),
    [handleFieldChange, handleFieldClearButtonClick, lane.delivery, lane.pickup]
  )

  const destinationInput = useMemo(
    () => (
      <>
        <LocationInput
          name="delivery"
          placeholder="Delivery"
          onChange={handleFieldChange}
          value={lane.delivery?.description}
          useFloatingLabel
          forcedPlaceholder={lane.pickup?.description ? 'Anywhere' : ''}
          onClearButtonClick={handleFieldClearButtonClick}
          data-testid="delivery"
        />
      </>
    ),
    [handleFieldChange, handleFieldClearButtonClick, lane.delivery, lane.pickup]
  )

  return (
    <SelectLaneContainer>
      <SelectLaneStopsContainer>
        <InputGroup>
          {originInput} {destinationInput}
        </InputGroup>
      </SelectLaneStopsContainer>

      <RadiusSelectWrapper>
        <Select
          placeholder={!isMobile ? '+ Radius' : 'Additional Radius'}
          suggestions={RADIUS_OPTIONS}
          onSuggestionSelect={handleRadiusChange}
          value={lane.radius}
        />
      </RadiusSelectWrapper>
    </SelectLaneContainer>
  )
}

SelectLane.propTypes = {
  onChange: PropTypes.func.isRequired,
  lane: laneType.isRequired,
}

SelectLane.defaultProps = {
  lane: {
    pickup: null,
    delivery: null,
    radius: DEFAULT_RADIUS_VALUE,
  },
}
